import React from "react";

export default function About({ dataAbout }) {
  const dataAboutInit = dataAbout.data?.data ? dataAbout?.data?.data[0] : {};

  return (
    <div id="about" className="py-24 container-page bg-[#F6F7FB]">
      <h1 className="mb-5 text-3xl 2xl:text-4xl font-[500]">Tentang Kami</h1>
      {dataAbout?.error ? (
        <div>Error: {dataAbout.error?.response?.data?.message}</div>
      ) : dataAbout?.data?.data?.length !== 0 ? (
        <>
          <div
            className="text-sm text-gray-700 rte-wrapper"
            dangerouslySetInnerHTML={{ __html: dataAboutInit?.about }}
          />

          <div className="grid grid-cols-1 mt-12 gap-7 lg:grid-cols-2">
            <div>
              <h1 className="mb-5 text-3xl 2xl:text-4xl font-[500]">Visi</h1>
              <div
                className="text-sm text-gray-700 rte-wrapper"
                dangerouslySetInnerHTML={{ __html: dataAboutInit?.vision }}
              />
            </div>

            <div>
              <h1 className="mb-5 text-3xl 2xl:text-4xl font-[500]">Misi</h1>
              <div
                className="text-sm text-gray-700 rte-wrapper"
                dangerouslySetInnerHTML={{ __html: dataAboutInit?.mission }}
              />
            </div>
          </div>
        </>
      ) : (
        <div>No about data available</div>
      )}
    </div>
  );
}
