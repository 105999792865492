import React from "react";
import { MailFilled, PhoneFilled } from "@ant-design/icons";
export default function Footer({ dataAbout, dataUnit }) {
  const dataAboutInit = dataAbout.data?.data ? dataAbout?.data?.data[0] : {};

  const scrollToElement = (id) => {
    const targetElement = document.getElementById(id);
    if (targetElement) {
      targetElement.scrollIntoView({ behavior: "smooth" });
    }
  };

  const items = [
    { key: "unit", name: "Unit Kerja" },
    { key: "about", name: "Tentang Kami" },
    // { key: "karir", name: "Karir" },
    { key: "footer", name: "Kontak" },
  ];

  return (
    <div id="footer" className="pt-24 container-page bg-[#111926]">
      <div className="grid grid-cols-1 gap-10 text-sm text-white md:grid-cols-3">
        {dataAbout?.error ? (
          <div>Error: {dataAbout.error?.response?.data?.message}</div>
        ) : dataAbout?.data?.data?.length !== 0 ? (
          <>
            <div>
              <img className="w-10 " src={dataAboutInit?.logo} alt="" />

              <p className="mt-5 text-sm">{dataAboutInit?.address}</p>

              <p className="text-sm">
                <span className="mr-2">
                  <PhoneFilled />
                </span>
                {dataAboutInit?.phone}
              </p>
              <p className="text-sm">
                <span className="mr-2">
                  <MailFilled />
                </span>
                {dataAboutInit?.email}
              </p>
            </div>

            <div>
              <h1 className="text-[16px] 2xl:text-lg font-[500]">Unit Kerja</h1>
              <ul>
                {dataUnit?.data?.data?.map((data) => (
                  <li className="mb-[2px] text-sm" key={data.id}>
                    {data?.name}
                  </li>
                ))}
              </ul>
            </div>

            <div>
              <h1 className="text-[16px] 2xl:text-lg font-[500]">Perusahaan</h1>

              <ul>
                {items.map((item) => (
                  <li
                    key={item.key}
                    onClick={() => scrollToElement(item.key)}
                    className="cursor-pointer mb-[2px] text-sm"
                  >
                    {item.name}
                  </li>
                ))}
              </ul>
            </div>
          </>
        ) : (
          <div>No about data available</div>
        )}
      </div>
      <div className="pb-3 mt-16">
        <hr className="border-t-1 border-[#3A4353]" />

        <p className="mt-5 text-sm text-white">
          Copyright &copy; 2024 Yayasan Islam Prambanan. All Rights Reserved.
        </p>
      </div>
    </div>
  );
}
