import { Carousel } from "antd";
import React from "react";
import Bg from "./assets/bg.jpg";
export default function Hero({ dataHero }) {
  return (
    <>
      <div
        id="hero"
        style={{
          backgroundImage: `url(${Bg})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          minHeight: "400px",
        }}
      >
        {dataHero.error ? (
          <div>Error: {dataHero.error?.response?.data?.message}</div>
        ) : dataHero.data?.data?.length !== 0 ? (
          <Carousel autoplay>
            {dataHero?.data?.data?.map((data) => (
              <div
                key={data.id}
                className="font-onest min-h-[400px] lg:h-[500px] 2xl:h-[700px] py-[100px] box-border !flex !items-center !justify-center"
              >
                <div className="grid items-center grid-cols-1 lg:grid-cols-2 container-page">
                  <div className="w-full ">
                    <h1 className="mb-3 text-4xl font-semibold 2xl:text-5xl">
                      {data?.title}
                    </h1>
                    <p>{data?.description}</p>
                    {data?.link && (
                      <a
                        href={data?.link}
                        className="bg-[black] text-white text-sm px-3 py-2 rounded-md hover:text-white"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Lihat Detail
                      </a>
                    )}
                  </div>
                  <div className="flex justify-center mt-10 lg:justify-end lg:mt-0">
                    <div>
                      <img
                        className="float-end lg:w-[400px] xl:w-[500px] 2xl:w-[600px] object-cover bg-center rounded-md"
                        src={data?.image}
                        alt=""
                      />
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </Carousel>
        ) : (
          <div>No hero data available</div>
        )}
      </div>
    </>
  );
}
