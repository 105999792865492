import React, { useEffect, useState } from "react";
import Header from "./Header";
import "./LandingPageStyle.css";
import Hero from "./Hero";
import Unit from "./Unit";
import About from "./About";
import Footer from "./Footer";
import axios from "axios";
import Backdrop from "components/UI/Backdrop";

export default function Master() {
  const [dataUnit, setDataUnit] = useState({ data: [], error: null });
  const [dataAbout, setDataAbout] = useState({ data: [], error: null });
  const [dataHero, setDataHero] = useState({ data: [], error: null });
  const [loading, setLoading] = useState(true);

  const { REACT_APP_HOST_API } = process.env;

  const fetchData = async (url, setter) => {
    try {
      const { data } = await axios.get(REACT_APP_HOST_API + url);
      setter((curr) => {
        return { ...curr, data: data };
      });
    } catch (error) {
      setter((curr) => {
        return { ...curr, error: error };
      });
    }
  };

  useEffect(() => {
    const fetchLandingContents = async () => {
      setLoading(true);

      await fetchData(
        "/landing-page/heros?mode=list&page=1&limit=10&is_active=true",
        setDataHero
      );
      await fetchData(
        "/landing-page/work-units?keyword=&mode=list",
        setDataUnit
      );
      await fetchData("/landing-page/abouts", setDataAbout);

      setLoading(false);
    };

    fetchLandingContents();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loading) {
    return <Backdrop open={loading} />;
  }

  return (
    <div className="font-onest">
      <Header dataAbout={dataAbout} />
      <main className="mt-[60px]">
        <Hero dataHero={dataHero} />
        <About dataAbout={dataAbout} />
        <Unit dataUnit={dataUnit} />
        <Footer dataAbout={dataAbout} dataUnit={dataUnit} />
      </main>
    </div>
  );
}
