import { MenuOutlined } from "@ant-design/icons";
import { Button, Drawer } from "antd";
import { useState } from "react";
export default function Header({ dataAbout }) {
  const [open, setOpen] = useState(false);

  const dataAboutInit = dataAbout.data?.data ? dataAbout?.data?.data[0] : {};

  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };

  const scrollToElement = (id) => {
    const targetElement = document.getElementById(id);
    if (targetElement) {
      targetElement.scrollIntoView({ behavior: "smooth" });
    }
  };

  const items = [
    { key: "hero", name: "Beranda" },
    { key: "unit", name: "Unit Kerja" },
    { key: "about", name: "Tentang Kami" },
    // { key: "karir", name: "Karir" },
    { key: "footer", name: "Kontak" },
  ];

  return (
    <div className="fixed left-0 top-0 z-10 w-full bg-white shadow-md">
      <header id="header" className="h-[60px]">
        {/* for desktop */}
        <div className="items-center justify-between hidden h-full container-page md:flex">
          <div className="flex items-center gap-5">
            <img src={dataAboutInit?.logo} alt="logo" className="w-10" />

            <ul className="flex gap-5 mt-3 text-sm">
              {items.map((item) => (
                <li
                  key={item.key}
                  onClick={() => scrollToElement(item.key)}
                  className="cursor-pointer"
                >
                  {item.name}
                </li>
              ))}
            </ul>
          </div>

          <div>
            <button className="bg-[black] text-white text-sm px-3 py-2 rounded-md">
              <a
                href="http://app.smafg.sch.id/"
                target="_blank"
                rel="noreferrer"
              >
                Area Member
              </a>
            </button>
          </div>
        </div>

        {/* for mobile */}
        <div className="flex items-center justify-between h-full md:hidden container-page">
          <img src={dataAboutInit?.logo} alt="logo" className="w-10" />

          <Button
            icon={<MenuOutlined />}
            type="text"
            onClick={showDrawer}
          ></Button>
        </div>
      </header>

      <Drawer
        placement="right"
        closable={false}
        onClose={onClose}
        open={open}
        key="right"
        width={200}
      >
        <div className="px-5 mt-10 ">
          {items.map((item) => (
            <p
              key={item.key}
              onClick={() => {
                scrollToElement(item.key);
                onClose();
              }}
              className="cursor-pointer"
            >
              {item.name}
            </p>
          ))}

          <button className="bg-[black] w-full text-white text-sm px-3 py-2 rounded-md">
            <a
              className="hover:text-white"
              href="http://app.smafg.sch.id/"
              target="_blank"
              rel="noreferrer"
            >
              Area Member
            </a>
          </button>
        </div>
      </Drawer>
    </div>
  );
}
