import { ConfigProvider } from "antd";
import { QueryClient, QueryClientProvider } from "react-query";
import { getApp } from "utils/helpers";
import "./App.css";

const queryClient = new QueryClient();

function App() {
  const CurrentApp = getApp();

  return (
    <ConfigProvider
      theme={{
        token: {
          fontFamily: "Source Sans Pro, sans-serif",
        },
      }}
    >
      <QueryClientProvider client={queryClient}>
        <div className="App">
          <CurrentApp />
        </div>
      </QueryClientProvider>
    </ConfigProvider>
  );
}

export default App;
