import React from "react";

export default function Unit({ dataUnit }) {
  return (
    <div id="unit" className="py-24 container-page">
      <div>
        <h1 className="mb-10 text-3xl 2xl:text-4xl font-[500] text-center">
          Unit Kerja di Bawah Yayasan Islam Prambanan
        </h1>

        {dataUnit.error ? (
          <div>Error: {dataUnit.error?.response?.data?.message}</div>
        ) : dataUnit?.data?.data?.length !== 0 ? (
          <div className="grid grid-cols-1 gap-[1rem] lg:grid-cols-3 sm:grid-cols-2 ">
            {dataUnit?.data?.data?.map((data) => (
              <a
                href={data?.link}
                key={data.id}
                target="_blank"
                rel="noreferrer"
              >
                <div className="border border-[#ccc] p-4 2xl:p-5 rounded-md hover:shadow-md cursor-pointer min-h-[200px] 2xl:min-h-40 flex flex-col justify-center">
                  <div className="flex items-center gap-3">
                    <img className="w-10 " src={data?.logo} alt="" />

                    <h1 className="font-[500] text-md md:text-lg">
                      {data?.name}
                    </h1>
                  </div>

                  <p className="mt-4 text-gray-700">{data?.description}</p>
                </div>
              </a>
            ))}
          </div>
        ) : (
          <div>No unit data available</div>
        )}
      </div>
    </div>
  );
}
