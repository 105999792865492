import { APPS } from "./constants";

// 1. dapatkan subdomain
export const getSubdomain = () => {
  // app.smafg.sch.id = -3
  // admin.smafg.sch.id = -3
  // admin.usamah-sistem.netlify.app = -3
  // admin.itfuturegate.xyz = -2
  // app.futuregate.id = -2

  // dapatkan url localhost
  const locationParts = window.location.hostname.split(".");

  let sliceTill = -2;

  // pengkondisian untuk localhost return localhost
  const isLocalhost = locationParts.slice(-1)[0] === "localhost";
  if (isLocalhost) sliceTill = -1;

  return locationParts.slice(0, sliceTill).join("");
};

// 2. return subdomain untuk di render di route

export const getApp = () => {
  const subDomain = getSubdomain();

  const mainApp = APPS.find((app) => app.main);

  // throw error apabila tidah ditemukan main app
  if (!mainApp) throw new Error("App not found!");

  // return main app apabila subdomain === ""
  if (subDomain === "") return mainApp.app;
  else {
    const app = APPS.find((app) => app.subdomain === subDomain);
    if (!app) return mainApp.app;

    return app.app;
    // return app dari variabel APPS jika subdomain !== ""
  }
};
