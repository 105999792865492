import React from "react";
import { Helmet } from "react-helmet";
import { useLocation } from "react-router-dom";
function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

function DynamicTitle() {
  const location = useLocation();
  const title = capitalizeFirstLetter(location.pathname.split("/")[1]);

  if (title === "") {
    return (
      <Helmet>
        <meta charSet="utf-8" />
        <title>Future Gate</title>
      </Helmet>
    );
  }

  return (
    <Helmet>
      <meta charSet="utf-8" />
      <title>{`${title} - Future Gate` || "Future Gate"}</title>
    </Helmet>
  );
}

export default DynamicTitle;
