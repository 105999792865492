import RootRoutes from "routes/RootRoutes";
import RoutesComponent from "routes/RoutesComponent";

export const APPS = [
  {
    subdomain: "www",
    app: RootRoutes,
    main: true,
  },
  {
    subdomain: "app",
    app: RoutesComponent,
    main: false,
  },
];
